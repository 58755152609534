<!--
  -- Created by zed on 2022/10/14
  -- 【商品修改页面】
-->
<template>
  <moe-page title="商品详情">
    <moe-form :model="goodsAmend" v-if="goodsAmend.id">
      <el-form-item label="商品主图">
        <moe-image :src="goodsAmend.coverUrl" width="100px" height="100px" />
      </el-form-item>
      <el-form-item label="商品轮播图">
        <moe-image :src="goodsAmend.imgsUrl" width="100px" height="100px" className="mr-20"/>
      </el-form-item>
      <el-form-item label="商品名称">
        {{ goodsAmend.name }}
      </el-form-item>
      <el-form-item label="商品介绍">
        {{ goodsAmend.introduce }}
      </el-form-item>
      <el-form-item label="商品价格">
        {{ goodsAmend.minPrice }} ~ {{ goodsAmend.maxPrice }}
      </el-form-item>
      <el-form-item label="销量">
        {{ goodsAmend.buyNum }}
      </el-form-item>
      <el-form-item label="库存">
        {{ goodsAmend.stock }}
      </el-form-item>
      <el-form-item label="商品详情">
        <div v-html="goodsAmend.infoPics"></div>
      </el-form-item>
    </moe-form>
  </moe-page>
</template>

<script>
export default {
  name: 'GoodsAmend',
  data() {
    //智能设备信息
    const goodsAmend = {
      name: '', //名称
      model: '', //型号
      img: '', //图片
      files: [], //说明文档
      imgsUrl: [],
    }


    return {
      goodsAmend, //智能设备信息
      imageList: [], //图片列表
      goodsAmendLoad: false, //提交状态
    }
  },
  created() {
    //获取商品信息
    this.getGoodsAmendDetail();
  },
  methods: {
    /**
     * 获取商品详情
     **/
    getGoodsAmendDetail() {
      // 获取商品ID
      const id = this.$route.query.id;
      // 查看商品详情
      this.$moe_api.GOODS_API.goodsAmendDetail({ id }).then((data) => {
        if (data.code == 200) {
          let a = data.result;
          this.goodsAmend = {
            id: a.id,
            sort: a.sort, //账号
            name: a.name, //类型
            introduce: a.introduce,   //介绍
            coverUrl: a.coverUrl,  //主图
            content: a.content,  //活动介绍
            stock: a.stock, //库存
            infoPics: a.infoPics,  //详情
            display: a.display,   //是否展示
            alias: a.alias,   //活动位置
            minPrice: a.minPrice,
            maxPrice: a.maxPrice,
            buyNum: a.buyNum,
            imgsUrl: a.imgsUrl.split(',')
          };
          console.log(this.goodsAmend,"imgsUrl")
        } else {
          this.$moe_msg.error(data.message);
        }
      });
    },
  },
}
</script>
